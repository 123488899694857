import Layout from 'components/Layout';
import React from 'react';
import seo from 'config/seo.json';

export default class privacyPolicy extends React.Component {
	render() {
		return (
			<Layout
				title={seo.privacy.title}
				description={seo.default.description}
				className="max-w-5xl mx-auto px-8 lg:px-16 py-12"
			>
				<header id="return-to-top" className="mb-8">
					<h1 className="font-bold mb-4">PRIVACY NOTICE</h1>
					<p className="italic text-sm">Last updated June 22, 2022</p>
				</header>
				<section className="mb-8">
					<p className="mb-4">
						Thank you for choosing to be part of our community at
						MooveGuru, LLC (
						<strong>"Company," "we," "us," or "our"</strong>). We
						are committed to protecting your personal information
						and your right to privacy. If you have any questions or
						concerns about this privacy notice or our practices with
						regard to your personal information, please contact us
						at admin@homekeepr.com.
					</p>
					<p className="mb-4">
						This privacy notice describes how we might use your
						information if you:
					</p>
					<ul className="mb-4">
						<li className="mb-4">
							Visit our website at{' '}
							<a
								href="http://www.homekeepr.com"
								target="_blank"
								rel="noreferrer"
							>
								http://www.homekeepr.com
							</a>
						</li>
						<li className="mb-4">
							Download and use our mobile application — HomeKeepr
						</li>
						<li>
							Engage with us in other related ways ― including any
							sales, marketing, or events
						</li>
					</ul>
					<p className="mb-4">
						In this privacy notice, if we refer to:
					</p>
					<ul className="mb-4">
						<li className="mb-4">
							<strong>"Website,"</strong> we are referring to any
							website of ours that references or links to this
							policy
						</li>
						<li className="mb-4">
							<strong>"App,"</strong> we are referring to any
							application of ours that references or links to this
							policy, including any listed above
						</li>
						<li>
							<strong>"Services,"</strong> we are referring to our
							Website, App, and other related services, including
							any sales, marketing, or events
						</li>
					</ul>
					<p className="mb-4">
						The purpose of this privacy notice is to explain to you
						in the clearest way possible what information we
						collect, how we use it, and what rights you have in
						relation to it. If there are any terms in this privacy
						notice that you do not agree with, please discontinue
						use of our Services immediately.
					</p>
					<p className="font-bold mb-8">
						Please read this privacy notice carefully, as it will
						help you understand what we do with the information that
						we collect.
					</p>
					<h2 className="font-bold mb-8">TABLE OF CONTENTS</h2>
					<ol className="mb-4">
						<li className="mb-4">
							<a href="#collect-information">
								1. WHAT INFORMATION DO WE COLLECT?
							</a>
						</li>
						<li className="mb-4">
							<a href="#use-information">
								2. HOW DO WE USE YOUR INFORMATION?
							</a>
						</li>
						<li className="mb-4">
							<a href="#shared-information">
								3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
							</a>
						</li>
						<li className="mb-4">
							<a href="#who-shared-information">
								4. WHO WILL YOUR INFORMATION BE SHARED WITH?
							</a>
						</li>
						<li className="mb-4">
							<a href="#cookies">
								5. DO WE USE COOKIES AND OTHER TRACKING
								TECHNOLOGIES?
							</a>
						</li>
						<li className="mb-4">
							<a href="#keep-information">
								6. HOW LONG DO WE KEEP YOUR INFORMATION?
							</a>
						</li>
						<li className="mb-4">
							<a href="#safe-information">
								7. HOW DO WE KEEP YOUR INFORMATION SAFE?
							</a>
						</li>
						<li className="mb-4">
							<a href="#minor-information">
								8. DO WE COLLECT INFORMATION FROM MINORS?
							</a>
						</li>
						<li className="mb-4">
							<a href="#privacy-rights">
								9. WHAT ARE YOUR PRIVACY RIGHTS?
							</a>
						</li>
						<li className="mb-4">
							<a href="#controls">
								10. CONTROLS FOR DO-NOT-TRACK FEATURES
							</a>
						</li>
						<li className="mb-4">
							<a href="#california-residents">
								11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC
								PRIVACY RIGHTS?
							</a>
						</li>
						<li className="mb-4">
							<a href="#notice-updates">
								12. DO WE MAKE UPDATES TO THIS NOTICE?
							</a>
						</li>
						<li className="mb-4">
							<a href="#contact-notice">
								13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
							</a>
						</li>
						<li>
							<a href="#review-update-delete">
								14. HOW CAN YOU REVIEW, UPDATE OR DELETE THE
								DATA WE COLLECT FROM YOU?
							</a>
						</li>
					</ol>
				</section>
				<section className="mb-8" id="collect-information">
					<h3 className="font-bold mb-4">
						1. WHAT INFORMATION DO WE COLLECT?
					</h3>
					<p className="font-bold">
						Personal information you disclose to us
					</p>
					<p className="italic mb-4">
						<strong>In Short:</strong> We collect personal
						information that you provide to us.
					</p>
					<p className="mb-4">
						We collect personal information that you voluntarily
						provide to us when you register on the Services, express
						an interest in obtaining information about us or our
						products and Services, when you participate in
						activities on the Services (such as by posting messages
						in our online forums or entering competitions, contests
						or giveaways) or otherwise when you contact us.
					</p>
					<p className="mb-4">
						The personal information that we collect depends on the
						context of your interactions with us and the Services,
						the choices you make and the products and features you
						use. The personal information we collect may include the
						following:
					</p>
					<p className="mb-4">
						<strong>Personal Information Provided by You.</strong>{' '}
						We collect passwords; email addresses; names; phone
						numbers; and other similar information.
					</p>
					<p className="mb-4">
						<strong>Payment Data.</strong>We may collect data
						necessary to process your payment if you make purchases,
						such as your payment instrument number (such as a credit
						card number), and the security code associated with your
						payment instrument. All payment data is stored by
						Stripe. You may find their privacy notice link(s) here:{' '}
						<a
							href="https://stripe.com/privacy."
							target="_blank"
							rel="noreferrer"
						>
							https://stripe.com/privacy.
						</a>
					</p>
					<p className="mb-4">
						All personal information that you provide to us must be
						true, complete and accurate, and you must notify us of
						any changes to such personal information.
					</p>
					<h4 className="mb-4">
						Information automatically collected
					</h4>
					<p className="italic mb-4">
						<strong>In Short:</strong> Some information — such as
						your Internet Protocol (IP) address and/or browser and
						device characteristics — is collected automatically when
						you visit our Services.
					</p>
					<p className="mb-4">
						We automatically collect certain information when you
						visit, use or navigate the Services. This information
						does not reveal your specific identity (like your name
						or contact information) but may include device and usage
						information, such as your IP address, browser and device
						characteristics, operating system, language preferences,
						referring URLs, device name, country, location,
						information about how and when you use our Services and
						other technical information. This information is
						primarily needed to maintain the security and operation
						of our Services, and for our internal analytics and
						reporting purposes.
					</p>
					<p className="mb-4">
						Like many businesses, we also collect information
						through cookies and similar technologies.
					</p>
					<p className="mb-4">The information we collect includes:</p>
					<ul className="mb-4">
						<li className="mb-4">
							<em>Log and Usage Data.</em> Log and usage data is
							service-related, diagnostic, usage and performance
							information our servers automatically collect when
							you access or use our Services and which we record
							in log files. Depending on how you interact with us,
							this log data may include your IP address, device
							information, browser type and settings and
							information about your activity in the Services
							(such as the date/time stamps associated with your
							usage, pages and files viewed, searches and other
							actions you take such as which features you use),
							device event information (such as system activity,
							error reports (sometimes called 'crash dumps') and
							hardware settings).
						</li>
						<li className="mb-4">
							<em>Device Data.</em> We collect device data such as
							information about your computer, phone, tablet or
							other device you use to access the Services.
							Depending on the device used, this device data may
							include information such as your IP address (or
							proxy server), device and application identification
							numbers, location, browser type, hardware model
							Internet service provider and/or mobile carrier,
							operating system and system configuration
							information.
						</li>
						<li>
							<em>Location Data.</em> We collect location data
							such as information about your device's location,
							which can be either precise or imprecise. How much
							information we collect depends on the type and
							settings of the device you use to access the
							Services. For example, we may use GPS and other
							technologies to collect geolocation data that tells
							us your current location (based on your IP address).
							You can opt out of allowing us to collect this
							information either by refusing access to the
							information or by disabling your Location setting on
							your device. Note however, if you choose to opt out,
							you may not be able to use certain aspects of the
							Services.
						</li>
					</ul>
					<h4 className="mb-4">
						Information collected through our App
					</h4>
					<p className="italic mb-4">
						<strong>In Short:</strong> We collect information
						regarding your push notifications, when you use our App.
					</p>
					<p className="mb-4">
						If you use our App, we also collect the following
						information:
					</p>
					<ul className="mb-4">
						<li>
							<em>Push Notifications.</em> We may request to send
							you push notifications regarding your account or
							certain features of the App. If you wish to opt-out
							from receiving these types of communications, you
							may turn them off in your device's settings.
						</li>
					</ul>
					<p className="mb-4">
						This information is primarily needed to maintain the
						security and operation of our App, for troubleshooting
						and for our internal analytics and reporting purposes.
					</p>
					<h4 className="mb-4">
						Information collected from other sources
					</h4>
					<p className="italic mb-4">
						<strong>In Short:</strong> We may collect limited data
						from public databases, marketing partners, and other
						outside sources.
					</p>
					<p>
						In order to enhance our ability to provide relevant
						marketing, offers and services to you and update our
						records, we may obtain information about you from other
						sources, such as public databases, joint marketing
						partners, affiliate programs, data providers, as well as
						from other third parties. This information includes
						mailing addresses, job titles, email addresses, phone
						numbers, intent data (or user behavior data), Internet
						Protocol (IP) addresses, social media profiles, social
						media URLs and custom profiles, for purposes of targeted
						advertising and event promotion.
					</p>
				</section>
				<section id="use-information" className="mb-8">
					<h3 className="font-bold mb-4">
						2. HOW DO WE USE YOUR INFORMATION?
					</h3>
					<p className="italic mb-4">
						<strong>In Short:</strong> We process your information
						for purposes based on legitimate business interests, the
						fulfillment of our contract with you, compliance with
						our legal obligations, and/or your consent.
					</p>
					<p className="mb-4">
						We use personal information collected via our Services
						for a variety of business purposes described below. We
						process your personal information for these purposes in
						reliance on our legitimate business interests, in order
						to enter into or perform a contract with you, with your
						consent, and/or for compliance with our legal
						obligations. We indicate the specific processing grounds
						we rely on next to each purpose listed below.
					</p>
					<p className="mb-4">
						We use the information we collect or receive:
					</p>
					<ul className="mb-4">
						<li className="mb-4">
							<strong>
								To facilitate account creation and logon
								process.
							</strong>{' '}
							If you choose to link your account with us to a
							third-party account (such as your Google or Facebook
							account), we use the information you allowed us to
							collect from those third parties to facilitate
							account creation and logon process for the
							performance of the contract.
						</li>
						<li className="mb-4">
							<strong>To post testimonials.</strong> We post
							testimonials on our Services that may contain
							personal information. Prior to posting a
							testimonial, we will obtain your consent to use your
							name and the content of the testimonial. If you wish
							to update, or delete your testimonial, please
							contact us at admin@homekeepr.com and be sure to
							include your name, testimonial location, and contact
							information.
						</li>
						<li className="mb-4">
							<strong>Request feedback.</strong> We may use your
							information to request feedback and to contact you
							about your use of our Services.
						</li>
						<li className="mb-4">
							<strong>
								To enable user-to-user communications.
							</strong>{' '}
							We may use your information in order to enable
							user-to-user communications with each user's
							consent.
						</li>
						<li className="mb-4">
							<strong>To manage user accounts.</strong> We may use
							your information for the purposes of managing our
							account and keeping it in working order.
						</li>
						<li className="mb-4">
							<strong>
								To send administrative information to you.
							</strong>{' '}
							We may use your personal information to send you
							product, service and new feature information and/or
							information about changes to our terms, conditions,
							and policies.
						</li>
						<li className="mb-4">
							<strong>To protect our Services.</strong> We may use
							your information as part of our efforts to keep our
							Services safe and secure (for example, for fraud
							monitoring and prevention).
						</li>
						<li className="mb-4">
							<strong>
								To enforce our terms, conditions and policies
								for business purposes, to comply with legal and
								regulatory requirements or in connection with
								our contract.
							</strong>
						</li>
						<li className="mb-4">
							<strong>
								To respond to legal requests and prevent harm.
							</strong>{' '}
							If we receive a subpoena or other legal request, we
							may need to inspect the data we hold to determine
							how to respond.
						</li>
						<li className="mb-4">
							<strong>Fulfill and manage your orders.</strong> We
							may use your information to fulfill and manage your
							orders, payments, returns, and exchanges made
							through the Services.
						</li>
						<li className="mb-4">
							<strong>
								Administer prize draws and competitions.
							</strong>{' '}
							We may use your information to administer prize
							draws and competitions when you elect to participate
							in our competitions.
						</li>
						<li className="mb-4">
							<strong>
								To deliver and facilitate delivery of services
								to the user.
							</strong>{' '}
							We may use your information to provide you with the
							requested service.
						</li>
						<li>
							<strong>
								To respond to user inquiries/offer support to
								users.
							</strong>{' '}
							We may use your information to respond to your
							inquiries and solve any potential issues you might
							have with the use of our Services.
						</li>
					</ul>
				</section>
				<section id="shared-information" className="mb-8">
					<h3 className="font-bold mb-4">
						3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
					</h3>
					<p className="italic mb-4">
						<strong>In Short:</strong> We only share information
						with your consent, to comply with laws, to provide you
						with services, to protect your rights, or to fulfill
						business obligations.
					</p>
					<p className="mb-4">
						We may process or share your data that we hold based on
						the following legal basis:
					</p>
					<ul className="mb-4">
						<li className="mb-4">
							<strong>Consent:</strong> We may process your data
							if you have given us specific consent to use your
							personal information for a specific purpose.
						</li>
						<li className="mb-4">
							<strong>Legitimate Interests:</strong> We may
							process your data when it is reasonably necessary to
							achieve our legitimate business interests.
						</li>
						<li>
							<strong>Performance of a Contract:</strong> Where we
							have entered into a contract with you, we may
							process your personal information to fulfill the
							terms of our contract.
						</li>
					</ul>
					<p className="mb-4">
						More specifically, we may need to process your data or
						share your personal information in the following
						situations:
					</p>
					<ul>
						<li className="mb-4">
							<strong>Business Transfers.</strong> We may share or
							transfer your information in connection with, or
							during negotiations of, any merger, sale of company
							assets, financing, or acquisition of all or a
							portion of our business to another company.
						</li>
						<li className="mb-4">
							<strong>
								Vendors, Consultants and Other Third-Party
								Service Providers.
							</strong>{' '}
							We may share your data with third-party vendors,
							service providers, contractors or agents who perform
							services for us or on our behalf and require access
							to such information to do that work. Examples
							include: payment processing, data analysis, email
							delivery, hosting services, customer service and
							marketing efforts. We may allow selected third
							parties to use tracking technology on the Services,
							which will enable them to collect data on our behalf
							about how you interact with our Services over time.
							This information may be used to, among other things,
							analyze and track data, determine the popularity of
							certain content, pages or features, and better
							understand online activity. Unless described in this
							notice, we do not share, sell, rent or trade any of
							your information with third parties for their
							promotional purposes.
						</li>
						<li className="mb-4">
							<strong>Third-Party Advertisers.</strong> We may use
							third-party advertising companies to serve ads when
							you visit or use the Services. These companies may
							use information about your visits to our Website(s)
							and other websites that are contained in web cookies
							and other tracking technologies in order to provide
							advertisements about goods and services of interest
							to you.
						</li>
						<li className="mb-4">
							<strong>Affiliates.</strong> We may share your
							information with our affiliates, in which case we
							will require those affiliates to honor this privacy
							notice. Affiliates include our parent company and
							any subsidiaries, joint venture partners or other
							companies that we control or that are under common
							control with us.
						</li>
						<li className="mb-4">
							<strong>Business Partners.</strong> We may share
							your information with our business partners to offer
							you certain products, services or promotions.
						</li>
						<li>
							<strong>Offer Wall.</strong> Our App may display a
							third-party hosted "offer wall." Such an offer wall
							allows third-party advertisers to offer virtual
							currency, gifts, or other items to users in return
							for the acceptance and completion of an
							advertisement offer. Such an offer wall may appear
							in our App and be displayed to you based on certain
							data, such as your geographic area or demographic
							information. When you click on an offer wall, you
							will be brought to an external website belonging to
							other persons and will leave our App. A unique
							identifier, such as your user ID, will be shared
							with the offer wall provider in order to prevent
							fraud and properly credit your account with the
							relevant reward. Please note that we do not control
							third-party websites and have no responsibility in
							relation to the content of such websites. The
							inclusion of a link towards a third-party website
							does not imply an endorsement by us of such website.
							Accordingly, we do not make any warranty regarding
							such third-party websites and we will not be liable
							for any loss or damage caused by the use of such
							websites. In addition, when you access any
							third-party website, please understand that your
							rights while accessing and using those websites will
							be governed by the privacy notice and terms of
							service relating to the use of those websites.
						</li>
					</ul>
				</section>
				<section id="who-shared-information" className="mb-8">
					<h3 className="font-bold mb-4">
						4. WHO WILL YOUR INFORMATION BE SHARED WITH?
					</h3>
					<p className="italic mb-4">
						<strong>In Short:</strong> We only share information
						with the following third parties.
					</p>
					<p>
						We only share and disclose your information with the
						following third parties. If we have processed your data
						based on your consent and you wish to revoke your
						consent, please contact us using the contact details
						provided in the section below titled "HOW CAN YOU
						CONTACT US ABOUT THIS NOTICE?".
					</p>
				</section>
				<section id="cookies" className="mb-8">
					<h3 className="font-bold mb-4">
						5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
					</h3>
					<p className="italic mb-4">
						<strong>In Short:</strong> We may use cookies and other
						tracking technologies to collect and store your
						information.
					</p>
					<p>
						We may use cookies and similar tracking technologies
						(like web beacons and pixels) to access or store
						information. Specific information about how we use such
						technologies and how you can refuse certain cookies is
						set out in our Cookie Notice.
					</p>
				</section>
				<section id="keep-information" className="mb-8">
					<h3 className="font-bold mb-4">
						6. HOW LONG DO WE KEEP YOUR INFORMATION?
					</h3>
					<p className="italic mb-4">
						<strong>In Short:</strong> We keep your information for
						as long as necessary to fulfill the purposes outlined in
						this privacy notice unless otherwise required by law.
					</p>
					<p className="mb-4">
						We will only keep your personal information for as long
						as it is necessary for the purposes set out in this
						privacy notice, unless a longer retention period is
						required or permitted by law (such as tax, accounting or
						other legal requirements). No purpose in this notice
						will require us keeping your personal information for
						longer than the period of time in which users have an
						account with us.
					</p>
					<p>
						When we have no ongoing legitimate business need to
						process your personal information, we will either delete
						or anonymize such information, or, if this is not
						possible (for example, because your personal information
						has been stored in backup archives), then we will
						securely store your personal information and isolate it
						from any further processing until deletion is possible.
					</p>
				</section>
				<section id="safe-information" className="mb-8">
					<h3 className="font-bold mb-4">
						7. HOW DO WE KEEP YOUR INFORMATION SAFE?
					</h3>
					<p className="italic mb-4">
						<strong>In Short:</strong> We aim to protect your
						personal information through a system of organizational
						and technical security measures.
					</p>
					<p>
						We have implemented appropriate technical and
						organizational security measures designed to protect the
						security of any personal information we process.
						However, despite our safeguards and efforts to secure
						your information, no electronic transmission over the
						Internet or information storage technology can be
						guaranteed to be 100% secure, so we cannot promise or
						guarantee that hackers, cybercriminals, or other
						unauthorized third parties will not be able to defeat
						our security, and improperly collect, access, steal, or
						modify your information. Although we will do our best to
						protect your personal information, transmission of
						personal information to and from our Services is at your
						own risk. You should only access the Services within a
						secure environment.
					</p>
				</section>
				<section id="minor-information" className="mb-8">
					<h3 className="font-bold mb-4">
						8. DO WE COLLECT INFORMATION FROM MINORS?
					</h3>
					<p className="italic mb-4">
						<strong>In Short:</strong> We do not knowingly collect
						data from or market to children under 18 years of age.
					</p>
					<p>
						We do not knowingly solicit data from or market to
						children under 18 years of age. By using the Services,
						you represent that you are at least 18 or that you are
						the parent or guardian of such a minor and consent to
						such minor dependent’s use of the Services. If we learn
						that personal information from users less than 18 years
						of age has been collected, we will deactivate the
						account and take reasonable measures to promptly delete
						such data from our records. If you become aware of any
						data we may have collected from children under age 18,
						please contact us at admin@homekeepr.com.
					</p>
				</section>
				<section id="privacy-rights" className="mb-8">
					<h3 className="font-bold mb-4">
						9. WHAT ARE YOUR PRIVACY RIGHTS?
					</h3>
					<p className="italic mb-4">
						<strong>In Short:</strong> You may review, change, or
						terminate your account at any time.
					</p>
					<p className="mb-4">
						If you are a resident in the EEA or UK and you believe
						we are unlawfully processing your personal information,
						you also have the right to complain to your local data
						protection supervisory authority. You can find their
						contact details here:{' '}
						<a
							href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm."
							target="_blank"
							rel="noreferrer"
						>
							https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
						</a>
					</p>
					<p className="mb-4">
						If you are a resident in Switzerland, the contact
						details for the data protection authorities are
						available here:{' '}
						<a
							href="https://www.edoeb.admin.ch/edoeb/en/home.html."
							target="_blank"
							rel="noreferrer"
						>
							https://www.edoeb.admin.ch/edoeb/en/home.html.
						</a>
					</p>
					<p className="mb-4">
						If you have questions or comments about your privacy
						rights, you may email us at{' '}
						<a href="mailto:admin@homekeepr.com">
							admin@homekeepr.com
						</a>
						.
					</p>
					<h4 className="mb-4">Account Information</h4>
					<p>
						If you would at any time like to review or change the
						information in your account or terminate your account,
						you can:
					</p>
					<ul className="mb-4">
						<li>
							Contact us using the contact information provided.
						</li>
					</ul>
					<p className="mb-4">
						Upon your request to terminate your account, we will
						deactivate or delete your account and information from
						our active databases. However, we may retain some
						information in our files to prevent fraud, troubleshoot
						problems, assist with any investigations, enforce our
						Terms of Use and/or comply with applicable legal
						requirements.
					</p>
					<p className="mb-4">
						<strong>Cookies and similar technologies:</strong> Most
						Web browsers are set to accept cookies by default. If
						you prefer, you can usually choose to set your browser
						to remove cookies and to reject cookies. If you choose
						to remove cookies or reject cookies, this could affect
						certain features or services of our Services. To opt-out
						of interest-based advertising by advertisers on our
						Services visit{' '}
						<a
							href="http://www.aboutads.info/choices/"
							target="blank"
							rel="noreferrer"
						>
							http://www.aboutads.info/choices/
						</a>
						.
					</p>
					<p className="mb-4">
						<strong>Opting out of email marketing:</strong> You can
						unsubscribe from our marketing email list at any time by
						clicking on the unsubscribe link in the emails that we
						send or by contacting us using the details provided
						below. You will then be removed from the marketing email
						list — however, we may still communicate with you, for
						example to send you service-related emails that are
						necessary for the administration and use of your
						account, to respond to service requests, or for other
						non-marketing purposes. To otherwise opt-out, you may:
					</p>
					<ul className="mb-4">
						<li>
							Contact us using the contact information provided.
						</li>
					</ul>
				</section>
				<section id="controls" className="mb-8">
					<h3 className="font-bold mb-4">
						10. CONTROLS FOR DO-NOT-TRACK FEATURES
					</h3>
					<p>
						Most web browsers and some mobile operating systems and
						mobile applications include a Do-Not-Track ("DNT")
						feature or setting you can activate to signal your
						privacy preference not to have data about your online
						browsing activities monitored and collected. At this
						stage no uniform technology standard for recognizing and
						implementing DNT signals has been finalized. As such, we
						do not currently respond to DNT browser signals or any
						other mechanism that automatically communicates your
						choice not to be tracked online. If a standard for
						online tracking is adopted that we must follow in the
						future, we will inform you about that practice in a
						revised version of this privacy notice.
					</p>
				</section>
				<section id="california-residents" className="mb-8">
					<h3 className="font-bold mb-4">
						11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY
						RIGHTS?
					</h3>
					<p className="italic mb-4">
						<strong>In Short:</strong> Yes, if you are a resident of
						California, you are granted specific rights regarding
						access to your personal information.
					</p>
					<p className="mb-4">
						California Civil Code Section 1798.83, also known as the
						"Shine The Light" law, permits our users who are
						California residents to request and obtain from us, once
						a year and free of charge, information about categories
						of personal information (if any) we disclosed to third
						parties for direct marketing purposes and the names and
						addresses of all third parties with which we shared
						personal information in the immediately preceding
						calendar year. If you are a California resident and
						would like to make such a request, please submit your
						request in writing to us using the contact information
						provided below.
					</p>
					<p className="mb-4">
						If you are under 18 years of age, reside in California,
						and have a registered account with a Service, you have
						the right to request removal of unwanted data that you
						publicly post on the Services. To request removal of
						such data, please contact us using the contact
						information provided below, and include the email
						address associated with your account and a statement
						that you reside in California. We will make sure the
						data is not publicly displayed on the Services, but
						please be aware that the data may not be completely or
						comprehensively removed from all our systems (e.g.
						backups, etc.).
					</p>
					<h4 className="mb-4">CCPA Privacy Notice</h4>
					<p className="mb-4">
						The California Code of Regulations defines a "resident"
						as:
					</p>
					<ol className="mb-4">
						<li className="mb-4">
							(1) every individual who is in the State of
							California for other than a temporary or transitory
							purpose and
						</li>
						<li>
							(2) every individual who is domiciled in the State
							of California who is outside the State of California
							for a temporary or transitory purpose
						</li>
					</ol>
					<p className="mb-4">
						All other individuals are defined as "non-residents."
					</p>
					<p className="mb-4">
						If this definition of "resident" applies to you, we must
						adhere to certain rights and obligations regarding your
						personal information.
					</p>
					<h4 className="mb-4">
						What categories of personal information do we collect?
					</h4>
					<p className="mb-4">
						We have collected the following categories of personal
						information in the past twelve (12) months:
					</p>
					<table className="mb-4">
						<thead>
							<tr>
								<th className="font-bold">Category</th>
								<th className="font-bold">Examples</th>
								<th className="font-bold">Collected</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>A. Identifiers</td>
								<td>
									Contact details, such as real name, alias,
									postal address, telephone or mobile contact
									number, unique personal identifier, online
									identifier, Internet Protocol address, email
									address and account name
								</td>
								<td>YES</td>
							</tr>
							<tr>
								<td>
									B. Personal information categories listed in
									the California Customer Records statute
								</td>
								<td>
									Name, contact information, education,
									employment, employment history and financial
									information
								</td>
								<td>YES</td>
							</tr>
							<tr>
								<td>
									C. Protected classification characteristics
									under California or federal law
								</td>
								<td>Gender and date of birth</td>
								<td>NO</td>
							</tr>
							<tr>
								<td>D. Commercial information</td>
								<td>
									Transaction information, purchase history,
									financial details and payment information
								</td>
								<td>NO</td>
							</tr>
							<tr>
								<td>E. Biometric information</td>
								<td>Fingerprints and voiceprints</td>
								<td>NO</td>
							</tr>
							<tr>
								<td>
									F. Internet or other similar network
									activity
								</td>
								<td>
									Browsing history, search history, online
									behavior, interest data, and interactions
									with our and other websites, applications,
									systems and advertisements
								</td>
								<td>YES</td>
							</tr>
							<tr>
								<td>G. Geolocation data</td>
								<td>Device location</td>
								<td>YES</td>
							</tr>
							<tr>
								<td>
									H. Audio, electronic, visual, thermal,
									olfactory, or similar information
								</td>
								<td>
									Images and audio, video or call recordings
									created in connection with our business
									activities
								</td>
								<td>NO</td>
							</tr>
							<tr>
								<td>
									I. Professional or employment-related
									information
								</td>
								<td>
									Business contact details in order to provide
									you our services at a business level, job
									title as well as work history and
									professional qualifications if you apply for
									a job with us
								</td>
								<td>NO</td>
							</tr>
							<tr>
								<td>J. Education Information</td>
								<td>
									Student records and directory information
								</td>
								<td>NO</td>
							</tr>
							<tr>
								<td>
									K. Inferences drawn from other personal
									information
								</td>
								<td>
									Inferences drawn from any of the collected
									personal information listed above to create
									a profile or summary about, for example, an
									individual’s preferences and characteristics
								</td>
								<td>YES</td>
							</tr>
						</tbody>
					</table>
					<p className="mb-4">
						We may also collect other personal information outside
						of these categories instances where you interact with us
						in-person, online, or by phone or mail in the context
						of:
					</p>
					<ul className="mb-4">
						<li className="mb-4">
							Receiving help through our customer support
							channels;
						</li>
						<li className="mb-4">
							Participation in customer surveys or contests; and
						</li>
						<li>
							Facilitation in the delivery of our Services and to
							respond to your inquiries.
						</li>
					</ul>
					<h4 className="mb-4">
						How do we use and share your personal information?
					</h4>
					<p className="mb-4">
						MooveGuru, LLC collects and shares your personal
						information through:
					</p>
					<ul className="mb-4">
						<li>Targeting cookies/Marketing cookies</li>
					</ul>
					<p className="mb-4">
						More information about our data collection and sharing
						practices can be found in this privacy notice.
					</p>
					<p className="mb-4">
						You can opt out from the selling of your personal
						information by disabling cookies in Cookie Preference
						Settings and clicking on the Do Not Sell My Personal
						Information link on our homepage.
					</p>
					<p className="mb-4">
						You may contact us by email at admin@homekeepr.com, or
						by referring to the contact details at the bottom of
						this document.
					</p>
					<p className="mb-4">
						If you are using an authorized agent to exercise your
						right to opt-out we may deny a request if the authorized
						agent does not submit proof that they have been validly
						authorized to act on your behalf.
					</p>
					<h4 className="mb-4">
						Will your information be shared with anyone else?
					</h4>
					<p className="mb-4">
						We may disclose your personal information with our
						service providers pursuant to a written contract between
						us and each service provider. Each service provider is a
						for-profit entity that processes the information on our
						behalf.
					</p>
					<p className="mb-4">
						We may use your personal information for our own
						business purposes, such as for undertaking internal
						research for technological development and
						demonstration. This is not considered to be "selling" of
						your personal data.
					</p>
					<p className="mb-4">
						MooveGuru, LLC has disclosed the following categories of
						personal information to third parties for a business or
						commercial purpose in the preceding twelve (12) months:
					</p>
					<ul className="mb-4">
						<li>
							Category B. Personal information, as defined in the
							California Customer Records law, such as your name,
							contact information, education, employment,
							employment history and financial information.
						</li>
						<li>
							Category K. Inferences drawn from any of the
							personal information listed above to create a
							profile or summary about, for example, an
							individual's preferences and characteristics.
						</li>
					</ul>
					<p className="mb-4">
						The categories of third parties to whom we disclosed
						personal information for a business or commercial
						purpose can be found under "
						<a href="#who-shared-information">
							WHO WILL YOUR INFORMATION BE SHARED WITH?
						</a>
						".
					</p>
					<p className="mb-4">
						MooveGuru, LLC has sold the following categories of
						personal information to third parties in the preceding
						twelve (12) months:
					</p>
					<ul className="mb-4">
						<li>
							Category B. Personal information, as defined in the
							California Customer Records law, such as your name,
							contact information, education, employment,
							employment history and financial information.
						</li>
					</ul>
					<p className="mb-4">
						The categories of third parties to whom we sold personal
						information are:
					</p>
					<p className="font-bold mb-4">
						Your rights with respect to your personal data
					</p>
					<p className="mb-4 underline">
						Right to request deletion of the data - Request to
						delete
					</p>
					<p className="mb-4">
						You can ask for the deletion of your personal
						information. If you ask us to delete your personal
						information, we will respect your request and delete
						your personal information, subject to certain exceptions
						provided by law, such as (but not limited to) the
						exercise by another consumer of his or her right to free
						speech, our compliance requirements resulting from a
						legal obligation or any processing that may be required
						to protect against illegal activities.
					</p>
					<p className="mb-4 underline">
						Right to be informed - Request to know
					</p>
					<p className="mb-4">
						Depending on the circumstances, you have a right to
						know:
					</p>
					<ul className="mb-4">
						<li className="mb-4">
							whether we collect and use your personal
							information;
						</li>
						<li className="mb-4">
							the categories of personal information that we
							collect;
						</li>
						<li className="mb-4">
							the purposes for which the collected personal
							information is used;
						</li>
						<li className="mb-4">
							whether we sell your personal information to third
							parties;
						</li>
						<li className="mb-4">
							the categories of personal information that we sold
							or disclosed for a business purpose;
						</li>
						<li className="mb-4">
							the categories of third parties to whom the personal
							information was sold or disclosed for a business
							purpose; and
						</li>
						<li>
							the business or commercial purpose for collecting or
							selling personal information.
						</li>
					</ul>
					<p className="mb-4">
						In accordance with applicable law, we are not obligated
						to provide or delete consumer information that is
						de-identified in response to a consumer request or to
						re-identify individual data to verify a consumer
						request.
					</p>
					<p className="mb-4 underline">
						Right to Non-Discrimination for the Exercise of a
						Consumer’s Privacy Rights
					</p>
					<p className="mb-4">
						We will not discriminate against you if you exercise
						your privacy rights.
					</p>
					<p className="mb-4 underline">Verification process</p>
					<p className="mb-4">
						Upon receiving your request, we will need to verify your
						identity to determine you are the same person about whom
						we have the information in our system. These
						verification efforts require us to ask you to provide
						information so that we can match it with information you
						have previously provided us. For instance, depending on
						the type of request you submit, we may ask you to
						provide certain information so that we can match the
						information you provide with the information we already
						have on file, or we may contact you through a
						communication method (e.g. phone or email) that you have
						previously provided to us. We may also use other
						verification methods as the circumstances dictate.
					</p>
					<p className="mb-4">
						We will only use personal information provided in your
						request to verify your identity or authority to make the
						request. To the extent possible, we will avoid
						requesting additional information from you for the
						purposes of verification. If, however, we cannot verify
						your identity from the information already maintained by
						us, we may request that you provide additional
						information for the purposes of verifying your identity,
						and for security or fraud-prevention purposes. We will
						delete such additionally provided information as soon as
						we finish verifying you.
					</p>
					<p className="mb-4 underline">Other privacy rights</p>
					<ul className="mb-4">
						<li className="mb-4">
							you may object to the processing of your personal
							data.
						</li>
						<li className="mb-4">
							you may request correction of your personal data if
							it is incorrect or no longer relevant, or ask to
							restrict the processing of the data.
						</li>
						<li>
							you can designate an authorized agent to make a
							request under the CCPA on your behalf. We may deny a
							request from an authorized agent that does not
							submit proof that they have been validly authorized
							to act on your behalf in accordance with the CCPA.
						</li>
					</ul>
					<p>
						To exercise these rights, you can contact us by email at
						admin@homekeepr.com, or by referring to the contact
						details at the bottom of this document. If you have a
						complaint about how we handle your data, we would like
						to hear from you.{' '}
					</p>
				</section>
				<section id="notice-updates" className="mb-8">
					<h3 className="font-bold mb-4">
						12. DO WE MAKE UPDATES TO THIS NOTICE?
					</h3>
					<p className="italic mb-4">
						<strong>In Short:</strong> Yes, we will update this
						notice as necessary to stay compliant with relevant
						laws.
					</p>
					<p>
						We may update this privacy notice from time to time. The
						updated version will be indicated by an updated
						"Revised" date and the updated version will be effective
						as soon as it is accessible. If we make material changes
						to this privacy notice, we may notify you either by
						prominently posting a notice of such changes or by
						directly sending you a notification. We encourage you to
						review this privacy notice frequently to be informed of
						how we are protecting your information.
					</p>
				</section>
				<section id="contact-notice" className="mb-8">
					<h3 className="font-bold mb-4">
						13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
					</h3>
					<p className="mb-4">
						If you have questions or comments about this notice, you
						may email us at{' '}
						<a href="mailto:admin@homekeepr.com">
							admin@homekeepr.com
						</a>{' '}
						or by post to:
					</p>
					<p className="mb-4">MooveGuru, LLC</p>
					<p className="mb-4">885 Woodstock Rd 30075</p>
					<p className="mb-4">Suite 430-206</p>
					<p className="mb-4">Roswell, GA 30075</p>
					<p>United States</p>
				</section>
				<section id="review-update-delete" className="mb-8">
					<h3 className="font-bold mb-4">
						14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
						COLLECT FROM YOU?
					</h3>
					<p>
						Based on the applicable laws of your country, you may
						have the right to request access to the personal
						information we collect from you, change that
						information, or delete it in some circumstances. To
						request to review, update, or delete your personal
						information, please visit:{' '}
						<a href="/contact">https://homekeepr.com/contact.</a>
					</p>
				</section>
				<a href="#return-to-top">Return to top?</a>
			</Layout>
		);
	}
}
